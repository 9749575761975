import { Project } from "../../types/project";
import ScotchTapeLink from "./ScotchTapeLink";
import TechStackBubbles from "./TechStackBubble";
import "./projectpostercard.css";

const ProjectPosterCard: React.FC<{ project: Project }> = ({ project }) => {
  return (
    <section className="project-poster-card">
      <div className="project-image">
        <img src={project.imageLink} alt={project.name} />
      </div>
      <div className="bottom-container">
        <div className="project-description-title">
          <p className="h4">{project.name}</p>
          <p className="text">{project.description}</p>
          <TechStackBubbles techStacks={project.techStacks} />
        </div>
        <div className="tape-links">
          <ScotchTapeLink site={project.site} code={project.code} />
        </div>
      </div>
    </section>
  );
};

export default ProjectPosterCard;
