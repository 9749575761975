import React from "react";
import { BasicInfoAboutContent } from "../data/basicInfoAboutContent";
import "./pages.css";

const About: React.FC = () => {
  const paragraphs: string[] = BasicInfoAboutContent.paragraphs;

  return (
    <article className="about">
      <p className="h4 page-header">About</p>
      <br />
      {paragraphs.map((paragraph, index) => (
        <>
          <p className="about-paragraph" key={index}>
            {paragraph}
          </p>
          <br />
        </>
      ))}
    </article>
  );
};

export default About;
