import { GitHub, LinkedIn } from "@mui/icons-material";
import "./socialmedialist.css";

const SocialMediaList = () => {
  return (
    <span className="socialmedia--span">
      <a
        href="https://github.com/crystalzipzap"
        target="_blank"
        rel="noopener noreferrer"
      >
        <GitHub className="icon" sx={{ fontSize: 55 }} />
      </a>
      <a
        href="https://www.linkedin.com/in/c-crystal-zhang/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <LinkedIn className="icon" sx={{ fontSize: 56 }} />
      </a>
    </span>
  );
};

export default SocialMediaList;
