import { School, VolunteerActivism, WorkHistory } from "@mui/icons-material";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Experience } from "../../types/experience";

type ExperienceTimelineProps = {
  experienceContent: Experience[];
};

const ExperienceTimeline = ({ experienceContent }: ExperienceTimelineProps) => {
  const getIcon = (type: string) => {
    switch (type) {
      case "education":
        return <School />;
      case "work":
        return <WorkHistory />;
      case "volunteer":
        return <VolunteerActivism />;
      default:
        return <WorkHistory />;
    }
  };

  return (
    <section className="experience-timeline">
      <VerticalTimeline lineColor="#AAE0FF" layout="1-column-left">
        {experienceContent.map((exp: Experience, index: number) => (
          <VerticalTimelineElement
            key={index}
            className={`vertical-timeline-element--${exp.type}`}
            contentStyle={{
              background: index % 2 === 0 ? "#629AE7" : "#AF7269",
              color: "#F5EED8",
            }}
            contentArrowStyle={{
              borderRight: `7px solid ${
                index % 2 === 0 ? "#629AE7" : "#AF7269"
              }`,
            }}
            date={exp.date}
            iconStyle={{ background: "#f5eed8", color: "#2c2c3e" }}
            icon={getIcon(exp.type)}
          >
            <h3 className="vertical-timeline-element-title">{exp.title}</h3>
            <h4 className="vertical-timeline-element-subtitle">
              {exp.organization}
            </h4>
            <p>{exp.description}</p>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </section>
  );
};

export default ExperienceTimeline;
