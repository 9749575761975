import { Experience } from "../types/experience";

export const experienceContent: Experience[] = [
  {
    title: "Junior Software Developer",
    organization: "Future Society of Central Oklahoma",
    type: "volunteer",
    description:
      "Contributed to an art management system's development with key technologies, engaged in collaborative problem-solving during pair programming, and ensured best practices in code review processes.",
    date: "December 2023 - present",
  },

  {
    title: "Master's in Computer Science",
    organization: "University of Oklahoma",
    type: "education",
    description: "Completed 33 hours of graduate level courses",
    date: "May 2023",
  },
  {
    title: "William Kerber Teaching Scholar",
    organization: "OU School of Computer Science",
    type: "work",
    description:
      "Assisted 30+ students per semester in IDE debugging, referenced technical documentation for optimal solutions, and simplified complex concepts using relatable examples and metaphors",
    date: "September 2021 - May 2023",
  },
  {
    title: "Research Computing Intern",
    organization: "Pacific Northwest National Laboratory",
    type: "work",
    description:
      "Designed, tested, and implemented a reusable popover component in a React-based app, contributing to UX/UI meetings, and presented project to key stakeholders",
    date: "May 2022 - August 2022",
  },
  {
    title: "Software Development Intern",
    organization: "Paycom Software Inc.",
    type: "work",
    description:
      "Developed Android wearable app mock-ups adhering to Paycom guidelines, conducted user research and scenario-based testing, tracked progress on Confluence, and completed extensive training in key web technologies",
    date: "May 2021 - August 2021",
  },
];
