import { BasicInfoAbout } from "../types/basicInfoAbout";

export const BasicInfoAboutContent: BasicInfoAbout = {
  name: 'Chenyi "Crystal" Schmidt',
  description:
    "I combine my tech skills and business insight to create web apps that are sleek, responsive, and smartly tuned to business goals.",
  paragraphs: [
    'Once upon a time, I was a tax accountant, diving into tax puzzles and racing against deadlines. I remember this one project with a spreadsheet so massive, not even my ultra-wide monitor could handle it all at once. That\'s when I started asking myself, "What if?" and began exploring the world of automation. And just like that, my journey to my first "Hello World" kicked off.',
    "Fast forward to the present, I have earned a Master's Degree in Computer Science. Working as a developer is immensely rewarding: it is a field that thrives on curiosity and is continually energized by the pursuit of growth and problem-solving. Possessing a distinctive combination of skills in software development and accounting, I am enthusiastic about utilizing this blend to make an impact.",
    "When I'm not geeking out on code, you'll find me chilling with my two cats and a lovable doggo, scoping out quirky local restaurants, or playing video games while sneaking in some workout on my Cubii.",
  ],
  title: "Aspiring Full-stack Developer",
};
