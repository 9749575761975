import "./scotchtapelink.css";

type ScotchTapeLinkProps = {
  site: string;
  code: string;
};

const ScotchTapeLink = ({ site, code }: ScotchTapeLinkProps) => {
  const defaultCodeString = "https://github.com/crystalzipzap";

  return (
    <section className="scotch-tape-link">
      <a
        href={site ? site : ""}
        target="_blank"
        rel="noopener noreferrer"
      >
        <p className="text-big">Site</p>
      </a>
      <a
        href={code ? code : defaultCodeString}
        target="_blank"
        rel="noopener noreferrer"
      >
        <p className="text-big">Code</p>
      </a>
    </section>
  );
};

export default ScotchTapeLink;
