import "./footer.css";

const Footer = () => {
  const year: number = new Date().getFullYear();
  return (
    <article className="footer">
      <p className="text-small footer-text">
        &copy; {year === 2023 ? "2023" : `2023 - ${year}`} chenyi.io
      </p>
      <p className="text-small footer-text">💖 & ( • ̀ω•́ )✧ & 💪</p>
    </article>
  );
};

export default Footer;
