import "./nametitlepersonalstmt.css";
import { BasicInfoAboutContent } from "../../data/basicInfoAboutContent";

const NameTitlePersonalStmt = () => {
  const BasicInfo = BasicInfoAboutContent;

  return (
    <section className="name-title-personal-stmt">
      <p className="h2 name">{BasicInfo.name}</p>
      <p className="text-big title">{BasicInfo.title}</p>
      <p className="personal-stmt">{BasicInfo.description}</p>
    </section>
  );
};

export default NameTitlePersonalStmt;
