import React from "react";
import ExperienceTimeline from "../components/ExperienceTimeline/ExperienceTimeline";
import { experienceContent } from "../data/experienceContent";

const Experience: React.FC = () => {
  return (
    <article>
      <p className="h4 page-header">Experience</p>
      <br />
      <ExperienceTimeline experienceContent={experienceContent} />
    </article>
  );
};

export default Experience;
