import MainLayout from "./layouts/MainLayout";
import "./styles/app.css";

const App = () => {
  return (
    <div className="app">
      <MainLayout />
    </div>
  );
};

export default App;
