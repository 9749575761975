import { Person3, RocketLaunch, Work } from "@mui/icons-material";
import { Link } from "react-scroll";
import "./navcolumn.css";

const NavColumn = () => {
  const updateHash = (hash: string) => {
    window.location.hash = hash;
  };

  return (
    <header className="NavColumnLinks">
      <Link
        to="about"
        spy={true}
        smooth={true}
        offset={-90}
        duration={269}
        className="navcolumn__link"
        onClick={() => updateHash("about")}
      >
        <span className="navcolumn__link--span">
          <Person3 className="icon icon-wiggle" sx={{ fontSize: 40 }} />
          <p className={`h3`}>About Me</p>
        </span>
      </Link>
      <Link
        to="experience"
        spy={true}
        smooth={true}
        offset={-90}
        duration={269}
        className="navcolumn__link"
        onClick={() => updateHash("experience")}
      >
        <span className="navcolumn__link--span">
          <Work className="icon icon-beat" sx={{ fontSize: 40 }} />
          <p className={`h3`}>Experience</p>
        </span>
      </Link>
      <Link
        to="projects"
        spy={true}
        smooth={true}
        offset={-90}
        duration={269}
        className="navcolumn__link"
        onClick={() => updateHash("projects")}
      >
        <span className="navcolumn__link--span">
          <RocketLaunch className="icon icon-fade" sx={{ fontSize: 40 }} />
          <p className={`h3`}>Projects</p>
        </span>
      </Link>
    </header>
  );
};

export default NavColumn;
