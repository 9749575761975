type TechStackBubbleProps = {
  techStacks: string[];
};

const TechStackBubbles: React.FC<TechStackBubbleProps> = ({ techStacks }) => {
  return (
    <section className="project-techs">
      {techStacks.map((tech, index) => (
        <p key={index} className="tech">
          {tech}
        </p>
      ))}
    </section>
  );
};

export default TechStackBubbles;
