import chenyiIoImage from "../assets/images/chenyi-io.png";
import { Project } from "../types/project";

export const ProjectContent: Project[] = [
  {
    name: "Chenyi.io",
    description:
      "I designed my personal website beginning with Figma to finalize the layout and then brought it to life using Visual Studio Code. Leveraging CSS Media Queries, I ensured it's fully responsive across standard displays, tablets, and mobile devices.",
    imageLink: chenyiIoImage,
    techStacks: ["React", "TypeScript", "CSS", "Netlify"],
    site: "https://chenyi.io",
    code: "",
  },
];
