import ProjectPosterCard from "../components/ProjectPosterCard/ProjectPosterCard";
import { ProjectContent } from "../data/projectContent";
import { Project } from "../types/project";
import "./pages.css";

const Projects = () => {
  const projectContent: Project[] = ProjectContent;

  return (
    <article>
      <p className="h4 page-header">Projects</p>
      <br />
      <section className="project-section">
        {projectContent.map((project, index) => (
          <ProjectPosterCard key={index} project={project} />
        ))}
      </section>
    </article>
  );
};

export default Projects;
