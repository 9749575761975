import Footer from "../components/Footer/Footer";
import NameTitlePersonalStmt from "../components/NameTitlePersonalStmt/NameTitlePersonalStmt";
import NavColumn from "../components/NavColumn/NavColumn";
import SocialMediaList from "../components/SocialMediaList/SocialMediaList";
import About from "../pages/About";
import Experience from "../pages/Experience";
import Projects from "../pages/Projects";
import "./mainlayout.css";

const MainLayout: React.FC = () => {
  return (
    <>
      <section className="layout-container">
        <section className="body-container">
          <section className="left-section">
            <NameTitlePersonalStmt />
            <NavColumn />
            <SocialMediaList />
          </section>
          <section className="right-section">
            <section className="content-container" id="about">
              <About />
            </section>
            <section className="content-container" id="experience">
              <Experience />
            </section>
            <section className="content-container" id="projects">
              <Projects />
            </section>
          </section>
        </section>
        <section className="footer-container" id="footer">
          <Footer />
        </section>
      </section>
    </>
  );
};

export default MainLayout;
